import { Box, Button, Typography } from "@mui/material"
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InstagramIcon from '@mui/icons-material/Instagram';

export const AddComponent = () => {
  return (
    <Box
      sx={{
        height: '52px',
        display: { xs: 'block', md: 'none' }
      }}
    >

      <Box
        position="fixed"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          bottom: 0,
          left: 0,
          width: '100%',
          height: '50px',
          background: '#606060',
        }}
      >
        <Button
          sx={{ width: '33%', height: '100%' }}
          component="a"
          href="tel:03-6281-7737"
          color="secondary"
          startIcon={<PhoneInTalkIcon sx={{ color: '#FFFFFF' }} />}
        >
          <Typography variant="button" sx={{ color: '#FFFFFF', paddingBottom: '4px', }}>電話</Typography>
        </Button>

        <Button
          sx={{
            width: '33%',
            height: '100%',
            backgroundColor: '#8f8f8f',
            borderRadius: 0,
          }}
          component="a"
          target="_blank"
          href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
          color="secondary"
          startIcon={<EventNoteIcon sx={{ color: '#FFFFFF' }} />}
        >
          <Typography variant="button" sx={{ color: '#FFFFFF', paddingBottom: '4px', }}>Web予約</Typography>
        </Button>

        <Button
          sx={{
            width: '33%',
            height: '100%',
          }}
          component="a"
          href="https://www.instagram.com/ocha_tsubame_dentalclinic"
          target="_blank"
          color="secondary"
          startIcon={<InstagramIcon sx={{ color: '#FFFFFF' }} />}
        >
          <Typography variant="button" sx={{ color: '#FFFFFF', paddingBottom: '4px', }}>Instagram</Typography>

        </Button>

      </Box>
    </Box>
  )
}