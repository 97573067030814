import { Box, Container, Stack, Typography } from "@mui/material"
import { Calendar } from "./ui/Calendar"
import { Link } from "react-router-dom"

export const Footer = () => {
  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <Stack
        marginY={4}
        display="flex"
        justifyContent="center"
        gap={2}
        alignItems="center"
        padding={2}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: {
              xs: '22px',
              md: '28px',
            }
          }}
        >
          御茶ノ水つばめ歯科・矯正歯科
        </Typography>
        <Typography variant="subtitle1">
          〒101-0052  東京都千代田区神田小川町3-8-10
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Link
            to="https://www.tiktok.com/@ocha_tsubame_dc"
            target="_blank"
          >
            <img
              src="/home/TikTok_Icon_Black_Circle.png"
              alt="TikTok"
              style={{ display: 'inlien-block', width: '32px', height: '32px', padding: '2px' }}
            />
          </Link>
          <Link
            to="https://lin.ee/CYUETiK"
            target="_blank"
          >
            <img
              src="/home/icons8-line-144.png"
              alt="line"
              style={{ width: '32px', height: '32px' }}
            />
          </Link>
          <Link
            target="_blank"
            to="https://www.instagram.com/ocha_tsubame_dentalclinic?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          >
            <img
              src="/home/sns_ig-150x150.jpeg"
              alt="instagram"
              style={{ width: '32px', height: '32px' }}
            />
          </Link>
        </Box>
      </Stack>

      <Stack
        display="flex"
        gap={3}
        padding={3}
      >

        <Calendar />

      </Stack >

      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#595c77",
        }}
      >
        <Stack
          maxWidth="500px"
          direction="row"
          gap={2}
          flexWrap="wrap"
          padding={2}
        >
          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/clinic-introduction/"
          >
            <Typography color="#FFFFFF">医院紹介</Typography>
          </Link>

          <Link
            to="/price"
          >
            <Typography color="#FFFFFF">料金表</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/cavity-treatment/"
          >
            <Typography color="#FFFFFF">一般歯科</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/orthodontic-treatment/"
          >
            <Typography color="#FFFFFF">矯正歯科</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/aesthetic/"
          >
            <Typography color="#FFFFFF">審美歯科</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/whitening/"
          >
            <Typography color="#FFFFFF">ホワイトニング</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/implant/"
          >
            <Typography color="#FFFFFF">インプラント</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/periodontal-disease/"
          >
            <Typography color="#FFFFFF">歯周病</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/oral-surgery/"
          >
            <Typography color="#FFFFFF">口腔外科</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/pediatric-dentistry/"
          >
            <Typography color="#FFFFFF">小児歯科</Typography>
          </Link>

          <Link
            target="_blank"
            to="https://blog.swallow-dental.com/category/preventive-dentistry/"
          >
            <Typography color="#FFFFFF">予防歯科</Typography>
          </Link>
        </Stack>

      </Stack>
    </Container>
  )
}