import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  icon: ReactNode,
  primaryText: ReactNode | string,
  secondaryText: ReactNode,
}

export const CircleDescription = (props: Props) => {
  return (
    <Stack
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      paddingTop={2}
      sx={{
        height: "180px",
        width: "180px",
        background: "#8c8c8c",
        textAlign: 'center',
        border: {
          xs: "4px solid #d4d4d4",
          md: "10px solid #d4d4d4",
        },
        borderRadius: { xs: '0', md: '50%' }
      }}
    >
      <Box
        position="absolute"
        top="5px"
      >
        {props.icon}
      </Box>
      <Typography
        variant="caption"
        sx={{
          fontSize: {
            xs: '14px',
            md: '16px',
          }
        }}
      >
        {props.primaryText}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: '#FFFFFF',
          fontSize: {
            xs: '12px',
            md: '14px',
          }
        }}
      >
        {props.secondaryText}
      </Typography>
    </Stack>
  )
};