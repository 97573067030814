import { Box, ButtonBase, Stack, Typography } from "@mui/material";

type Props = {
  src: string,
  title: string,
  text: string,
  to: string,
};

export const SubjectCard = (props: Props) => {
  return (
    <ButtonBase
      component={Box}
      padding={2}
      display="flex"
      justifyContent="space-between"
      gap={1}
      alignItems="center"
      sx={{
        padding: '16px',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        width: {
          xs: '100%',
          sm: 'calc(50% - 8px)',
        }
      }}
      onClick={() => {
        window.open(props.to, '_blank');
      }}
    >
      <Box
        padding="3px 8px"
        sx={{
          backgroundColor: '#E8EAF6',
          borderRadius: '16px',
        }}
      >
        <img
          src={props.src} alt="image"
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      </Box>
      <Stack
        width="100%"
        display="flex"
        gap={2}
        justifyContent="flex-start"
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: '#777',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          {props.title}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0,0,0,0.67)',
            lineHeight: 1.5,
          }}
        >
          {props.text}
        </Typography>
      </Stack>
    </ButtonBase >
  )
}