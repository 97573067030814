import { AppBar, Box, ButtonBase, Fade, IconButton, List, ListItem, ListItemButton, ListItemText, Modal, Toolbar, Typography } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import EventNoteIcon from '@mui/icons-material/EventNote';

export const Header = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const closeModal = () => {
    setIsOpenModal(false);
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          minHeight: '50px !important',
          backgroundColor: 'rgba(255,255,255,0.9)',
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            height: '50px',
          }}
        >
          <Box
            display="flex"
            component="a"
            href="/"
          >
            <img
              src="/logo.webp"
              alt="logo"
              style={{
                height: '30px',
                display: 'inline-block',
                marginRight: '8px',
              }}
            />
            <Typography
              variant="subtitle1"
              component="h1"
              sx={{
                fontSize: { xs: '16px', lg: '18px' },
                lineHeight: '30px',
              }}
            >
              御茶ノ水つばめ歯科・矯正歯科
            </Typography>
          </Box>

          <Box
            gap={1}
            marginLeft={2}
            sx={{
              display: { xs: 'none', lg: 'flex' }
            }}
          >
            <a href="/">
              <Typography variant="subtitle2">
                トップ
              </Typography>
            </a>

            {/*
            <Link to="/about">
              <Typography variant="subtitle2">
                当院について
              </Typography>
            </Link>
          */}

            <a href="/#medical_subject">
              <Typography variant="subtitle2">
                診療内容
              </Typography>
            </a>


            <a href="/price">
              <Typography variant="subtitle2">
                料金表
              </Typography>
            </a>

            <a href="/#access">
              <Typography variant="subtitle2">
                アクセス
              </Typography>
            </a>

            <a href="/#medical_hours">
              <Typography variant="subtitle2">
                診療時間
              </Typography>
            </a>

            <a href="https://blog.swallow-dental.com/">
              <Typography variant="subtitle2">
                ブログ
              </Typography>
            </a>

          </Box>

          <Box
            position="absolute"
            justifyContent="center"
            gap={1}
            alignItems="center"
            paddingY={1}
            paddingX={2}
            sx={{
              display: { xs: 'none', lg: 'flex' },
              right: '10px',
              backgroundColor: '#595c77',
              border: '1px solid #575757',
              borderRadius: '30px',
              width: 'fit-content',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            component="a"
            target="_blank"
            href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
          >
            <EventNoteIcon
              sx={{
                color: '#FFFFFF',
                fontSize: '28px',
              }}
            />
            <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
              WEBで予約
            </Typography>
          </Box>

          <Box
            position="absolute"
            gap={0}
            sx={{
              right: 0,
              top: 0,
              display: { xs: 'flex', lg: 'none' }
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '50px',
                height: '50px',
              }}
              component="a"
              href="tel:03-6281-7737"
            >
              <PhoneInTalkIcon
                sx={{
                  color: '#616161',
                }}
              />
            </Box>

            <ButtonBase
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: '50px',
                  height: '50px',
                  background: '#424242',
                }}
              >
                <MenuIcon />
              </Box>
            </ButtonBase>
          </Box>
        </Toolbar>
      </AppBar>

      <Modal
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
      >
        <Fade in={isOpenModal}>
          <Box
            position="relative"
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF',
            }}
          >
            <List
              sx={{ paddingTop: "50px" }}
            >
              <ListItem>
                <ListItemButton component="a" href="/#" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">トップ</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              {/*<ListItem>
                <ListItemButton component="a" href="/" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">当院について</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
                  */}

              <ListItem>
                <ListItemButton component="a" href="/#medical_subject" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">診療科目</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton component="a" href="/price" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">料金表</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              {/*
              <ListItem>
                <ListItemButton component="a" href="/" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">ドクター紹介</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
                  */}


              <ListItem>
                <ListItemButton component="a" href="/#access" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">アクセス</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>


              <ListItem>
                <ListItemButton component="a" href="/#medical_hours" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">診療時間</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton component="a" href="https://blog.swallow-dental.com/" onClick={closeModal}>
                  <ListItemText
                    primary={
                      <Typography variant="h3">ブログ</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton
                  component="a"
                  href="tel:03-6281-7737"
                  onClick={() => {
                    if (typeof window.gtag === 'function') {
                      window.gtag('event', 'click_tel_reservation', {
                        event_category: 'button',
                        event_label: 'reservation_button',
                      });
                    }
                    closeModal()
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h3">電話で予約する</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton
                  component="a"
                  target="_blank"
                  href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
                  onClick={() => {
                    if (typeof window.gtag === 'function') {
                      window.gtag('event', 'click_web_reservation', {
                        event_category: 'button',
                        event_label: 'reservation_button',
                      });
                    }
                    closeModal()
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h3">WEBで予約する</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List >

            <IconButton
              sx={{
                position: 'absolute',
                right: '8px',
                top: '8px',
              }}
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>

          </Box >
        </Fade>
      </Modal >
    </Box >
  )
}